import baseStyled, { ThemeProvider as BaseThemeProvider, } from 'styled-components'
import { useVibrantTheme } from './useVibrantTheme';

export type VibrantTheme = typeof useVibrantTheme;

/**
 * `styled` object for themed styled-components
 */
const styled = baseStyled;
/**
 * Enchanced ThemeProvider
 */
const ThemeProvider = ({ children }: React.PropsWithChildren) => {
  const { theme } = useVibrantTheme()
  return (
    <BaseThemeProvider theme={theme}>{children}</BaseThemeProvider>
  )
};

export { ThemeProvider, styled }