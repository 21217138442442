/**
 * Imports
 */
import { Box, SpinnerAnimation, Text } from 'components';
import { useTranslation } from 'react-i18next';

/**
 * ValidationView
 */
function ValidationView() {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column">
      <Box height={70} width={56}>
        <SpinnerAnimation></SpinnerAnimation>
      </Box>
      <Box maxWidth="180px">
        <Text fontFamily="bold" textAlign="center" variant="h6">
          {t('views.validation.text')}
        </Text>
      </Box>
    </Box>
  );
}

export { ValidationView };
