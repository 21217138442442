/**
 * Imports
 */
import {
  border,
  BordersProps,
  color,
  ColorProps,
  compose,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
  layout,
  LayoutProps,
  textStyle,
  TextStyleProps,
} from 'styled-system';
import { styled } from 'theme';

/**
 * Types
 */
export type TextSizeProps =
  | 'h0'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p1'
  | 'p2'
  | 'p3'
  | 'ui1'
  | 'ui2'
  | 'ui3';

export type TextProps = BordersProps &
  ColorProps &
  SpaceProps &
  LayoutProps &
  TextStyleProps &
  TypographyProps & {
    variant?: TextSizeProps;
  };

/**
 * Styling
 */
const Text = styled.div<TextProps>(
  {},
  variant({
    variants: {
      h0: {
        color: 'textHeading',
        fontFamily: 'body',
        fontSize: 'h0',
        lineHeight: 'h0',
      },
      h1: {
        color: 'textHeading',
        fontFamily: 'heading',
        fontSize: 'h1',
        lineHeight: 'h1',
      },
      h2: {
        color: 'textHeading',
        fontFamily: 'heading',
        fontSize: 'h2',
        lineHeight: 'h2',
      },
      h3: {
        color: 'textHeading',
        fontFamily: 'heading',
        fontSize: 'h3',
        lineHeight: 'h3',
      },
      h4: {
        color: 'textHeading',
        fontFamily: 'heading',
        fontSize: 'h4',
        lineHeight: 'h4',
      },
      h5: {
        color: 'textHeading',
        fontFamily: 'heading',
        fontSize: 'h5',
        lineHeight: 'h5',
      },
      h6: {
        color: 'textHeading',
        fontFamily: 'heading',
        fontSize: 'h6',
        lineHeight: 'h6',
      },
      p1: {
        color: 'textBody',
        fontFamily: 'regular',
        fontSize: 'p1',
        lineHeight: 'p1',
      },
      p2: {
        color: 'textBody',
        fontFamily: 'body',
        fontSize: 'p2',
        lineHeight: 'p2',
      },
      p3: {
        color: 'textBody',
        fontFamily: 'body',
        fontSize: 'p3',
        lineHeight: 'p3',
      },
      ui1: {
        color: 'textUi',
        fontFamily: 'ui',
        fontSize: 'ui1',
        lineHeight: 'ui1',
      },
      ui2: {
        color: 'textUi',
        fontFamily: 'ui',
        fontSize: 'ui2',
        lineHeight: 'ui2',
      },
      ui3: {
        color: 'textUi',
        fontFamily: 'ui',
        fontSize: 'ui3',
        lineHeight: 'ui3',
      },
    },
  }),
  compose(typography, space, color, border, layout, textStyle),
);

/**
 * Exports
 */
export { Text };
