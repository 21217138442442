/**
 * Imports
 */
import { ReactComponent as SuccessLogo } from 'assets/icons/checkmark.svg';
import { ReactComponent as ErrorLogo } from 'assets/icons/close.svg';
import { ReactComponent as RefreshLogo } from 'assets/icons/refresh.svg';
import { Box, Text } from 'components';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { VibrantColors } from 'theme';

type StatusState = 'canceled' | 'success' | 'unavailable';

/**
 * CONST
 */
const STATUS_VIEWES: {
  [key in StatusState]: {
    icon: ReactNode;
    text: string;
    subtext?: string;
    bgColor: string;
  };
} = {
  canceled: {
    icon: <ErrorLogo height={24} width={24}></ErrorLogo>,
    text: 'views.canceled.text',
    bgColor: VibrantColors.Shades.Orange300,
  },
  success: {
    icon: <SuccessLogo height={24} width={24}></SuccessLogo>,
    text: 'views.success.text',
    bgColor: VibrantColors.Shades.Green300,
  },
  unavailable: {
    icon: <ErrorLogo height={24} width={24}></ErrorLogo>,
    text: 'views.unavailable.text',
    subtext: 'views.unavailable.subtext',
    bgColor: VibrantColors.Shades.Purple300,
  },
};

/**
 * Types
 */
type StatusViewProps = {
  status: StatusState;
};

/**
 * StatusView
 */
const StatusView: React.FC<StatusViewProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      maxWidth="228px">
      <Box
        backgroundColor={STATUS_VIEWES[props.status].bgColor}
        height={64}
        width={64}
        borderColor="borderDark"
        borderWidth={2}
        borderStyle="solid"
        borderRadius="50%"
        display="flex"
        justifyContent="center"
        alignItems="center">
        {STATUS_VIEWES[props.status].icon}
      </Box>
      <Text variant="h6" fontFamily="bold" mt="24px" textAlign="center">
        {t(`${STATUS_VIEWES[props.status].text}`)}
      </Text>
      {STATUS_VIEWES[props.status]?.subtext && (
        <Text variant="p2" mt="16px" textAlign="center">
          {t(`${STATUS_VIEWES[props.status].subtext}`)}
        </Text>
      )}
    </Box>
  );
};

export { StatusView };
