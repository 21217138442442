/**
 * Types
 */
export type ColorShadesType =
  | 'Green300'
  | 'Green500'
  | 'Green700'
  | 'Grey100'
  | 'Grey200'
  | 'Grey300'
  | 'Grey400'
  | 'Grey500'
  | 'Grey600'
  | 'Grey700'
  | 'Grey800'
  | 'Grey900'
  | 'Gold100'
  | 'Gold500'
  | 'Orange300'
  | 'Orange500'
  | 'Orange700'
  | 'Purple300'
  | 'Purple500'
  | 'Purple700'
  | 'Yellow300'
  | 'Yellow500'
  | 'Yellow700';
/**
 * Shades
 */
const ColorShades: { [key in ColorShadesType]: string } = {
  // Grey
  Grey100: '#F7F7F4',
  Grey200: '#E1E1DD',
  Grey300: '#D1D0CD',
  Grey400: '#B6B5B3',
  Grey500: '#969593',
  Grey600: '#767573',
  Grey700: '#555553',
  Grey800: '#353533',
  Grey900: '#151513',
  // Gold
  Gold100: '#F9F8F1',
  Gold500: '#DBD5A9',
  // Green / Grass
  Green300: '#DDF4B8',
  Green500: '#A1CF3F',
  Green700: '#2E580D',
  // Orange / Coral
  Orange300: '#FCD0B1',
  Orange500: '#F8893A',
  Orange700: '#863036',
  // Purple / Lavender
  Purple300: '#E7DAFB', // Brand / Secondary
  Purple500: '#C4A3F5',
  Purple700: '#2B0A5C',
  // Yellow / Beam
  Yellow300: '#FFFBC2',
  Yellow500: '#FDEF5E', // Brand / Primary
  Yellow700: '#665412', 
}
/**
 * Base
 */
const BaseColors = {
  BrandPrimary: ColorShades.Yellow500,
  BrandSecondary: ColorShades.Purple300
}
/**
 * Background
 */
const BackgroundColors = {
  BackgroundPrimary: ColorShades.Grey100,
  BackgroundSecondary: ColorShades.Grey200,
  BackgroundError: ColorShades.Orange300,
  BackgroundSuccess: ColorShades.Green300,
}
/**
 * Border
 */
const BorderColors = {
  BorderPrimary: ColorShades.Grey200,
  BorderDark: ColorShades.Grey900
}
/**
 * Border
 */
const TextColors = {
  Heading: ColorShades.Grey900,
  Body: ColorShades.Grey900,
  UI: ColorShades.Grey900
}
/**
 * The one Theme to rule them all
 */
const VibrantColors = {
  Background: BackgroundColors, 
  Base: BaseColors,
  Border: BorderColors, 
  Shades: ColorShades,
  Text: TextColors,
}
/**
 * Exports
 */
export { VibrantColors }