/**
 * Imports
 */
import { Box } from 'components';

/**
 * Center
 */
function Center({ children }: React.PropsWithChildren) {
  return (
    <Box
      background="backgroundPrimary"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      flex={1}>
      {children}
    </Box>
  );
}

export { Center };
