/**
 * Imports
 */
import Rive from '@rive-app/react-canvas';

/**
 * SpinnerAnimation
 */
function SpinnerAnimation() {
  return <Rive src="spinner_animation.riv" width="100%" height="100%" />;
}

export { SpinnerAnimation };
