import React from 'react';
import './App.css';
// fonts
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import './fonts/PPNeueMontreal-Medium.woff';
import './fonts/PPNeueMontreal-Medium.woff2';
import './fonts/PPNeueMontreal-Regular.woff';
import './fonts/PPNeueMontreal-Regular.woff2';
import './fonts/PPNeueMontreal-SemiBold.woff';
import './fonts/PPNeueMontreal-SemiBold.woff2';
import './translations/config';

import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { BrandingFooter, Center } from './components';
import { firebaseConfig } from './firebase';
import { StatusView, ValidationView } from './views';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import detectBrowserLanguage from 'detect-browser-language';

const LANG_CODES = ['en', 'da', 'es', 'no', 'se', 'fo', 'de'];

const App = () => {
  const { i18n } = useTranslation();
  const { paymentLinkId } = useParams();
  const [error, setError] = React.useState(false);
  const [canceled, setCanceled] = React.useState(false);
  const [paid, setPaid] = React.useState(false);

  const findDocument = async () => {
    if (!paymentLinkId) return;
    console.log('---> This is the paymentLinkId', paymentLinkId);
    const docRef = doc(firestore, 'paymentlink', paymentLinkId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();

      if (data.canceled) {
        console.log('Link has been canceled!');
        setCanceled(true);
      } else if (data?.paymentStatus === 'paid') {
        setPaid(true);
      } else {
        window.location.href = data.quickpayUrl;
        console.log('Document data:', docSnap.data());
      }
    } else {
      // doc.data() will be undefined in this case
      console.log('No such document!');
      setError(true);
    }
  };

  /**
   * Detect language in browser and set language
   */
  const setLanguage = () => {
    const browserLanguage = detectBrowserLanguage();

    if (browserLanguage) {
      const langCode = browserLanguage?.split('-')[0];
      LANG_CODES.includes(langCode)
        ? i18n.changeLanguage(langCode)
        : i18n.changeLanguage('en');
    }
  };

  React.useEffect(() => {
    findDocument();
    setLanguage();
  }, []);

  if (error) {
    return (
      <Center>
        <StatusView status="unavailable"></StatusView>
        <BrandingFooter></BrandingFooter>
      </Center>
    );
  }
  if (canceled) {
    return (
      <Center>
        <StatusView status="canceled"></StatusView>
        <BrandingFooter></BrandingFooter>
      </Center>
    );
  }
  if (paid) {
    return (
      <Center>
        <StatusView status="success"></StatusView>
        <BrandingFooter></BrandingFooter>
      </Center>
    );
  }
  return (
    <Center>
      <ValidationView></ValidationView>
      <BrandingFooter></BrandingFooter>
    </Center>
  );
};

export const firebase = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebase);

getAnalytics(firebase);

export default App;
