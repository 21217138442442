/**
 * Imports
 */
import {
  alignItems,
  AlignItemsProps,
  alignSelf,
  AlignSelfProps,
  background,
  BackgroundProps,
  border,
  BorderProps,
  color,
  ColorProps,
  flexbox,
  flexDirection,
  FlexDirectionProps,
  flexGrow,
  FlexGrowProps,
  FlexProps,
  flexWrap,
  FlexWrapProps,
  fontFamily,
  FontFamilyProps,
  justifyContent,
  JustifyContentProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
  position,
  PositionProps,
} from 'styled-system';
import { styled } from '../../theme';

/**
 * Types
 */

type BoxProps = AlignItemsProps &
  AlignSelfProps &
  BackgroundProps &
  BorderProps &
  ColorProps &
  FlexDirectionProps &
  FlexGrowProps &
  FlexProps &
  FlexWrapProps &
  FontFamilyProps &
  JustifyContentProps &
  LayoutProps &
  TextAlignProps &
  PositionProps &
  SpaceProps;

/**
 * Styling
 */
const Box = styled.div<BoxProps>`
  ${alignItems}
  ${alignSelf}
  ${background}
  ${border}
  ${color}
  ${flexbox}
  ${flexDirection}
  ${flexGrow}
  ${flexWrap}
  ${fontFamily}
  ${justifyContent}
  ${layout}
  ${position}
  ${space}
  ${textAlign}
`;

/**
 * Exports
 */
export { Box };
