// Import the functions you need from the SDKs you need
//import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
//import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const getUrl = () => {
  const hostname = window.location.hostname
  if (hostname.includes('sandbox')) {
    return 'sandbox'
  }
  if (hostname.startsWith('pay.vibrant.app')) {
    return 'production'
  }
  if (hostname.includes('staging')) {
    return 'staging'
  }
  return 'development'
}

export const url = getUrl()

const getConfig = () => {

  if (url.includes('sandbox')) {
    console.log('Running sandbox')
    return {
      apiKey: "AIzaSyD_weWuZ1WrlLZ-upNZmVpclQRSJxfxPaM",
      authDomain: "vibrant-sandbox.firebaseapp.com",
      projectId: "vibrant-sandbox",
      storageBucket: "vibrant-sandbox.appspot.com",
      messagingSenderId: "1043005723546",
      appId: "1:1043005723546:web:b04164ea0f1e8007a1a023"
    }
  } else if (url.includes('production')) {
    console.log('Running production')
    return {
      apiKey: "AIzaSyAbIbKSw_SmtYM8WLqs9ZUoFsb07q7i28o",
      authDomain: "vibrantly-production.firebaseapp.com",
      databaseURL: "https://vibrantly-production.firebaseio.com",
      projectId: "vibrantly-production",
      storageBucket: "vibrantly-production.appspot.com",
      messagingSenderId: "836686013166",
      appId: "1:836686013166:web:98fc9eddb358173cc409ff",
      measurementId: "G-V3S6TVSNE9"
    }
    
  } else if (url.includes('staging')) {
    console.log('Running Staging')
    return {
      apiKey: "AIzaSyCU_fvCkTrWK_Cf_CfQtlVb4U2AL1FFEuk",
      authDomain: "vibrant-staging-e738b.firebaseapp.com",
      projectId: "vibrant-staging-e738b",
      storageBucket: "vibrant-staging-e738b.appspot.com",
      messagingSenderId: "790345936628",
      appId: "1:790345936628:web:9174576fab0c43b300b8a1",
      measurementId: "G-S43ZXD2GD0"
    }
    
  }
  console.log('development')
  return {
    apiKey: "AIzaSyAygs2N2rQPt5ZUeiNtuJ2Z6a-fS8LUDXk",
    authDomain: "vibrant-development.firebaseapp.com",
    projectId: "vibrant-development",
    storageBucket: "vibrant-development.appspot.com",
    messagingSenderId: "896337117681",
    appId: "1:896337117681:web:2932053f3b3d73591cc450",
    measurementId: "G-4Z1G767LB6"
  }

}

export const firebaseConfig = getConfig()
// Initialize Firebase

