/**
 * imports
 */
import i18n from 'i18next';
import en from './en.json';
import da from './da.json';
import fo from './fo-FO.json';
import no from './nb.json';
import se from './sv.json';
import de from './de-DE.json';
import { initReactI18next } from 'react-i18next';
/**
 * Exports
 */
export const resources = {
  en,
  da,
  fo,
  no,
  se,
  de,
} as const;
export const defaultNS = 'translations';
/**
 * Localization setup
 */
i18n
  // .use(Backend)
  .use(initReactI18next)
  .init({
    react: {
      bindI18n: 'loaded languageChanged',
      bindI18nStore: 'added',
      useSuspense: true,
    },
    fallbackLng: 'en',
    supportedLngs: ['en', 'da', 'es', 'no', 'se', 'fo', 'de'],
    resources,
    ns: ['text'], // have a common namespace used around the full app
    defaultNS: 'text',
  });

export default i18n;
