import { Box, Text } from 'components';
import { ReactComponent as VibrantLogo } from 'assets/icons/vibrant-logo.svg';
import { useTranslation } from 'react-i18next';

const BrandingFooter = () => {
  const { t } = useTranslation();
  return (
    <Box
      position="absolute"
      bottom={32}
      display="flex"
      alignItems="center"
      justifyContent="center">
      <Box mr="8px">
        <Text variant="ui3">{t('footer')}</Text>
      </Box>
      <VibrantLogo width={76}></VibrantLogo>
    </Box>
  );
};

export { BrandingFooter };
