import { useMemo } from 'react';
import { VibrantColors } from './colors';

/**
 * useVibrantTheme
 */
const useVibrantTheme = () => {
  /**
   * Colors
   */
  const colors = useMemo(() => {
    return {
      // Base colors
      brandPrimary: VibrantColors.Base.BrandPrimary,
      brandSecondary: VibrantColors.Base.BrandSecondary,
      // Background
      backgroundSuccess: VibrantColors.Background.BackgroundSuccess,
      backgroundError: VibrantColors.Background.BackgroundError,
      // Borders
      borderPrimary: VibrantColors.Border.BorderPrimary,
      borderDark: VibrantColors.Border.BorderDark,
      // Text
      textHeading: VibrantColors.Text.Heading,
      textBody: VibrantColors.Text.Body,
      textUI: VibrantColors.Text.UI,
    };
  }, []);
  /**
   * Fonts
   */
  const fonts = useMemo(() => {
    return {
        heading: 'PPNeueMontreal-Medium',
        body: 'PPNeueMontreal-Medium',
        ui: 'PPNeueMontreal-Regular',
        regular: 'PPNeueMontreal-Regular',
        medium: 'PPNeueMontreal-Medium',
        bold: 'PPNeueMontreal-SemiBold',
      }
  }, []);
  /**
   * Font sizes
   */
  const fontSizes = useMemo(() => {
    return {
      h0: '128px',
      h1: '80px',
      h2: '64px',
      h3: '48px',
      h4: '48px',
      h5: '32px',
      h6: '24px',
      p1: '20px',
      p2: '16px',
      p3: '14px',
      ui1: '20px',
      ui2: '16px',
      ui3: '14px',
      }
  }, []);
  /**
   * Font weight
   */
  const fontWeights = useMemo(() => {
    return {
      regular: 400,
      medium: 500,
      bold: 600,
      }
  }, []);
  /**
   * Line heights 
   */
  const lineHeights = useMemo(() => {
    return {
      h0: '90%',
      h1: '90%',
      h2: '90%',
      h3: '90%',
      h4: '90%',
      h5: '90%',
      h6: '110%',
      p1: '140%',
      p2: '140%',
      p3: '140%',
      ui1: '110%',
      ui2: '110%',
      ui3: '110%',
      }
  }, []);

  /**
   * The Theme
   */
  const theme = {
    colors,
    fonts,
    fontSizes,
    fontWeights,
    lineHeights,
  }

  return { theme };
};

export { useVibrantTheme };
