import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ThemeProvider } from './theme';
import { Center, Text } from 'components';

const router = createBrowserRouter([
  {
    path: '/:paymentLinkId',
    element: (
      <ThemeProvider>
        <App />
      </ThemeProvider>
    ),
  },
  {
    path: '/',
    element: (
      <ThemeProvider>
        <Center>
          <Text variant="h6" mb="16px">
            The URL does not contain a payment link ID
          </Text>
          <Text variant="p2">
            Please consult your issuer for assistance or request a new one
          </Text>
        </Center>
      </ThemeProvider>
    ),
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
